/* You can add global styles to this file, and also import other style files */

.rail-theme {
  color: #0a3e71;
}

body {
  background-color: #d7d7d7;
}

.header {
  margin-left: 1rem;
  margin-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;
}

.carousel-control-next,
.carousel-control-prev , .carousel-indicators {
    filter: invert(100%);
}

.form-control {
  /*width: 300px;*/
}

.form-label {
  margin-block: 0.5rem;
  font-size: smaller;
  font-weight: bold;
  padding-left: 0.5rem;
}

.content {
  padding-top: 100px;
  padding-bottom: 1rem;
}


.history {
  font-size: medium;
  padding-inline: 0.75rem;
}


.categories:hover {
  color: #009ee0;
  cursor: pointer;
}

.bottom {
  align-items: end;
}

.align-center {
  align-items: center;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 50%;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #0a3e71;
  border-radius: 10px;
  /*border: 1px solid gray;*/
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #009ee0;
}

.main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
}
@media screen and (max-width: 992px) {
  .main-grid{
    grid-template-columns: 1fr;
  }
}

.form-control:focus {
  background-color: #d9f3ff;
}

input.form-control:read-only {
  background-color: #a7a7a7;
  cursor: not-allowed;
}

select.form-control {
  cursor: pointer;
}

.update-info {
  background-color: rgba(0, 255, 0, 0.55);
  padding: 0.5rem;
  border: 2px solid gray;
  border-radius: 1rem;
  margin-top: 1rem;
}

.basic-card {
  border: solid gray;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0.5rem;
}

.card-hover:hover {
  border: solid #0a3e71;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .move-in {
    margin-left: 3rem;
  }
}

.category-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  margin: 1rem;
}

@media screen and (max-width: 1400px) {
  .category-list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .category-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .category-list {
    grid-template-columns: repeat(3, 1fr);
  }
  .image-container > img {
    max-height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .category-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .category-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-inline: auto;
  margin-top: 0.5rem;
  max-height: calc(100vh - 150px - 2rem);
  overflow: auto;
}

@media screen and (max-width: 1200px) {
  .product-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .product-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .product-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .product-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.management-content {
  max-height: calc(100vh - 175px - 3rem);
  overflow: auto;
}

.product-image {
  position: relative;
  display: grid;
  place-content: center;
  overflow: hidden;
}

.product-image > img {
  max-height: 200px;
}

.deleted {
  position: relative;
  overflow: hidden;
}

.deleted::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent gray overlay */
  z-index: 1; /* Places it above content */
  border-radius: 0.5rem;
}

.deleted::after {
  cursor: pointer;
  content: "WIRD GELÖSCHT";
  position: absolute;
  top: 50%;
  left: 50%;
  color: red;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-weight: bold;
  font-size: 2rem;
  pointer-events: none;
  z-index: 2;
}

.drop-zone {
  height: 14rem;
  margin: auto;
  border: 2px dotted #0782d0;
  border-radius: 30px;
  padding: 0.5rem;
}

.drop-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 14rem;
  color: #0782d0;
}
